.titulo {
  margin-bottom: 20px;
  color: #05b6b7;
  font-size: large;
  font-weight: 500;
}
.nome,
.numero_documento {
  width: 48% !important;
  margin-bottom: 8 !important;
  margin-right: 4% !important;
}

.apelido,
.validade_documento {
  width: 48% !important;
  margin-bottom: 8 !important;
}

@media (max-width: 800px) {
  .nome,
  .apelido {
    width: 100% !important;
  }
}
@media (max-width: 1025px) {
  .numero_documento,
  .validade_documento {
    width: 100% !important;
  }
}
