.navBody {
  background: red;
  height: 10vh;
}

.topbar {
  /* width: calc(100% - 150px); */
  width: 50vw;
  height: '100px';
}

.antTabs {
  margin: auto;
}

@media (max-width: 600px) {
  .antTabs {
    width: 100vw;
  }
}
