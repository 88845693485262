.observacoes {
  width: 35vw !important;
  margin-bottom: 8 !important;
  margin-right: 4% !important;
}

@media (max-width: 584px) {
  .observacoes {
    width: 100% !important;
  }
}

@media (min-width: 584px) and (max-width: 800px) {
  .observacoes {
    width: 80% !important;
  }
}
@media (min-width: 800px) and (max-width: 1025px) {
  .observacoes {
    width: 100% !important;
  }
}

@media (min-width: 1025px) {
  .observacoes {
    width: 100% !important;
  }
}
