.titulo {
  margin-bottom: 20px;
  color: #05b6b7;
  font-size: large;
  font-weight: 500;
}

.step0energia {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.step1energia {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.step3energia {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media (max-width: 800px) {
  .step0energia {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .step1energia {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .step3energia {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
