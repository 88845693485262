.card {
  /* background: #e8e8e8; */
  /* border-radius: 10px; */
  padding: 10px;
  border-left: 2.5px solid #e8e8e8;
}

.dados {
  margin-left: 10px;
}

.card h3 {
  color: #08c9c8;
  font-weight: 600;
}
.card h5 {
  color: #03a6a959;
  margin-bottom: 5px;
}

.card span {
  font-weight: 400;
}
.card > span {
  font-weight: 400;
}

.label {
  color: #03a6a9;
}

.final {
  margin-bottom: 20px;
}

/* .card > span {
  font-size: 50px;
  color: crimson;
} */
