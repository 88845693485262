.titulo {
  margin-bottom: 20px;
  color: #05b6b7;
  font-size: large;
  font-weight: 500;
}
.morada {
  width: 60% !important;
  margin-bottom: 8 !important;
  margin-right: 4% !important;
}

.cpe {
  width: 36% !important;

  margin-bottom: 8 !important;
}
.cui {
  width: 36% !important;

  margin-bottom: 8 !important;
}
.localidade {
  width: 60% !important;
  margin-bottom: 8 !important;
  margin-right: 4% !important;
}

.cod_postal {
  width: 36% !important;

  margin-bottom: 8 !important;
}

@media (max-width: 800px) {
  .morada {
    width: 100% !important;
  }
  .cpe {
    width: 100% !important;
  }
  .localidade {
    width: 100% !important;
  }
  .cod_postal {
    width: 100% !important;
  }
}
